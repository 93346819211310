import $ from 'jquery'
import consumer from '../channels/consumer'

let PersonCounter = 1
let ReservedSeats = 0
let InitialClear = 1
let EventChannel = null
let startDateValue = null
let endDateValue = null
const _MS_PER_DAY = 1000 * 60 * 60 * 24

window.addEventListener('load', function () {
  // Set values initially if they are present
  startDateValue = document.getElementById('event_startdate')?.value
  endDateValue = document.getElementById('event_enddate')?.value
})

$(document).on('change', '#event_startdate', function () {
  startDateValue = this.value
  if (document.getElementById('event_enddate').value) {
    endDateValue = document.getElementById('event_enddate').value
    // E.g. is 01.01. until 08.01. allowed, and dateDiffInDays() would return '8' in this case
    if (dateDiffInDays(startDateValue, endDateValue) <= 8) {
      calcRows(endDateValue, startDateValue)
    } else {
      startDateValue = 0
      removeEventDurations()
      alert('Nach der Auswahl des Starts der Veranstaltung liegt das Ende der Veranstaltung zu weit in der Zukunft. Bitte das Ende der Veranstaltung neu auswählen.')
    }
  }
  document.getElementById('event_enddate').setAttribute('max', getMaxEndDate(startDateValue))
  document.getElementById('event_enddate').setAttribute('min', startDateValue)
})

$(document).on('change', '#event_enddate', function () {
  endDateValue = this.value
  if (document.getElementById('event_startdate').value) {
    startDateValue = document.getElementById('event_startdate').value
    document.getElementById('event_enddate').setAttribute('max', getMaxEndDate(startDateValue))
    if (dateDiffInDays(startDateValue, endDateValue) <= 8) {
      calcRows(endDateValue, startDateValue)
    } else {
      removeEventDurations()
      alert('Nach der Auswahl des Endes der Veranstaltung liegt Start der Veranstaltung zu weit in der Vergangenheit. Bitte den Start der Veranstaltung neu auswählen.')
    }
  }
})

function calcRows (endDate, startDate) {
  if (startDate <= endDate || checkSameDay(startDate, endDate)) { // if startDate before endDate
    const neededAmountOfDays = dateDiffInDays(startDate, endDate)
    removeEventDurations()
    // Creating the labels with the correct Day to the durations
    const dateList = buildDateList(startDate, neededAmountOfDays)
    for (let i = 0; i < neededAmountOfDays; i++) {
      $('#event-duration-group').append('<div class="form-group row">' +
        '<label class="col-form-label round_inputs_label required" id="dynamicDateLabel" for="event_event_durations_attributes_' + i + '_duration">' + dateList[i] + '</label>' +
        '<div class="round_inputs_control">' +
        '<div class="input-group">' +
        '<input required="required" class="form-control" type="number" min="0" max="24" step="0.1" name="event[event_durations_attributes][' + i + '][duration]" id="event_event_durations_attributes_' + i + '_duration">' +
        '<input type="hidden" name="event[event_durations_attributes][' + i + '][date]" value="' + dateList[i] + '">' +
        '<div class="input-group-append">' +
        '<span class="input-group-text">Stunden</span>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>')
    }
  } else {
    removeEventDurations()
    alert('Start der Veranstaltung muss früher oder am gleichen Tag wie das Ende der Veranstaltung sein!')
  }
}

function removeEventDurations () {
  const eventDurationGroup = document.getElementById('event-duration-group')
  while (eventDurationGroup.lastElementChild) {
    eventDurationGroup.removeChild(eventDurationGroup.lastElementChild)
  }
}

function checkSameDay (startDate, endDate) {
  const a = new Date(startDate)
  const b = new Date(endDate)
  // Discard the time and time-zone information.
  const startAsUtc = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const endAsUtc = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())
  return startAsUtc === endAsUtc
}

function dateDiffInDays (startDate, endDate) {
  const a = new Date(startDate)
  const b = new Date(endDate)
  // Discard the time and time-zone information.
  const startAsUtc = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const endAsUtc = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())
  return Math.floor((endAsUtc - startAsUtc) / _MS_PER_DAY) + 1
}

function buildDateList (startDate, amountOfDays) {
  const result = [] // result -> Date list for the labels of the columns
  const tempDateStart = new Date(startDate)
  const tempToConvertDate = new Date((tempDateStart.setDate(tempDateStart.getDate() - 1)))
  for (let i = 0; i < amountOfDays; i++) {
    const tempCurrentRows = new Date(tempToConvertDate.setDate(tempToConvertDate.getDate() + 1))
    result.push(tempCurrentRows.getDate() + '/' + (tempToConvertDate.getMonth() + 1) + '/' + tempCurrentRows.getFullYear())
  }
  return result
}

function getMaxEndDate (date) {
  const transformDate = new Date(date)
  return transformDate.getFullYear() + '-' + (transformDate.getMonth() + 1) + '-' + (transformDate.getDate() + 7) + 'T00:00:00'
}

const CloneFormElement = function (classname, counter) {
  const element = $('.' + classname).clone()
  element.removeClass(classname)
  const inputfield = element.find('input[id]')
  if (inputfield.prop('type') === 'checkbox') {
    inputfield.prop('checked', false)
  } else {
    inputfield.val('')
  }
  const id = inputfield.prop('id') + '-' + counter
  inputfield.prop('id', id)
  inputfield.prop('name', inputfield.prop('name') + '-' + counter)

  const label = element.find('label[for]')
  label.prop('for', id)

  return element
}
$(document).on('change', '#company_member_number', function () {
  const memberNumber = this.value
  if (isNaN(memberNumber) || !(parseInt(memberNumber) >= 100000 && parseInt(memberNumber) <= 199999)) {
    alert('Eingegebener Wert entspricht nicht der üblichen Form. Falls dies beabsichtigt ist, fahren Sie einfach fort. Ansonsten überprüfen Sie bitte Ihre Kundennummer!')
  }
})

const GetReservedSeats = function () {
  const seating = []
  $('.reserved').each(function (index) {
    seating.push($(this).closest('g').prop('id'))
  })
  return seating
}
const UpdateSeating = function () {
  let seating = ''
  $('.reserved').each(function (index) {
    seating = seating + $(this).closest('g').data('name') + '<br/>'
  })
  if (seating === '') {
    seating = 'bisher wurden keine Sitze ausgewählt'
  }
  $('#sitze').html(seating)
  $('#seats').val(GetReservedSeats())
  $('#sitze_headline').html('ausgewählte Sitzplätze ' + ReservedSeats + '/' + PersonCounter)
  if (ReservedSeats === PersonCounter) {
    $(':input[type="submit"]').prop('disabled', false)
    $('#sitze_hint').hide()
  }
}

const ReserveSeat = function (id) {
  $('.cls-18').each(function (index) {
    if ($(this).closest('g').prop('id') === id) {
      if (!$(this).hasClass('reserved')) {
        $(this).removeClass('free')
        $(this).addClass('blocked')
      }
    }
  })
}

const FreeSeat = function (id) {
  $('.cls-18').each(function (index) {
    if ($(this).closest('g').prop('id') === id) {
      $(this).removeClass('blocked')
      $(this).addClass('free')
    }
  })
}

$(document).on('turbolinks:load', function () {
  if (EventChannel !== null) {
    console.log('turbolinks load EvenChannel is null')
    consumer.disconnect()
    InitialClear = 1
    ConnectToActionCable()
  }
})

$(document).on('submit', '#eventsubscription', function (event) {
  console.log($('#event').data('seating'))
  if ($('#event').data('seating') === 0) {
    // no seating
    console.log('no seating available, ignore')
  } else {
    if (ReservedSeats !== PersonCounter) {
      $(':input[type="submit"]').prop('disabled', true)
      $('#sitze_hint').show()
      return false
    }
  }
})

const ConnectToActionCable = function () {
  EventChannel = consumer.subscriptions.create({
    channel: 'EventChannel',
    event_id: $('#event').data('event-id')
  }, {
    connected () {
    // Called when the subscription is ready for use on the server
    },

    disconnected () {
      console.log('disconnected')
      InitialClear = 1
    },

    received (data) {
      if (data.msg_type === 'event') {
        if (InitialClear === 1) {
          const ReservedSeats = GetReservedSeats()
          console.log('event - initialclear 1')
          $.each(data.data, function (index) {
            ReserveSeat(this)
          })
          // unblock the rest
          $('.cls-18').each(function (index) {
            if (!$(this).hasClass('blocked')) {
              $(this).addClass('free')
            }
          })
          $.each(ReservedSeats, function (index) {
            $(this).addClass('reserved')
            $(this).removeClass('free')
          })
          EventChannel.send({ msg_type: 'temp_seats', data: ReservedSeats })

          InitialClear = 0
        } else {
          console.log('event - initialclear 0')
          // someone new is in the ring, send my temporary seatings
          EventChannel.send({ msg_type: 'temp_seats', data: GetReservedSeats() })
        }
      } else if (data.msg_type === 'reserved') {
        ReserveSeat(data.data)
      } else if (data.msg_type === 'free') {
        FreeSeat(data.data)
      } else if (data.msg_type === 'temp_seats') {
        console.log('tempseats ' + data.data)
        $.each(data.data, function (index) {
          ReserveSeat(this)
        })
      } else if (data.msg_type === 'someone_left') {
        // keep my seatings, free others
        // build new from backend
        $('.cls-18').each(function (index) {
          if ($(this).hasClass('blocked')) {
            $(this).removeClass('blocked')
            $(this).addClass('free')
          }
        })
        $.each(data.data, function (index) {
          ReserveSeat(this)
        })
        console.log('someone left ' + data.data)

        // wait for everybody to cleanup
        setTimeout(EventChannel.send({ msg_type: 'temp_seats', data: GetReservedSeats() }), 500)
      }
    }
  })
}

$(document).on('click', 'button.btn-remove-additional-attendees', function () {
  const allFormColumns = document.querySelectorAll(':not(.attendee_name_field):not(.attendee_surname_field):not(.attendee_email_field):not(.attendee_mandate_field):not(.attendee_trainee_field).col-md-3')
  if (allFormColumns.length) {
    for (let i = 0; i < 4; i++) {
      allFormColumns.item(i).remove()
    }
    PersonCounter--
  }
})

$(function () {
  ConnectToActionCable()
  $(document).on('click', 'button.btn-additional-attendees', function () {
    if (PersonCounter <= 4) {
      PersonCounter++
      CloneFormElement('attendee_name_field', PersonCounter).appendTo('.additional-users')
      CloneFormElement('attendee_surname_field', PersonCounter).appendTo('.additional-users')
      CloneFormElement('attendee_email_field', PersonCounter).appendTo('.additional-users')
      CloneFormElement('attendee_trainee_field', PersonCounter).appendTo('.additional-users')
      UpdateSeating()
    } else {
      $('.toast').toast('show')
    }
    return false
  })

  $(document).on('click', 'rect.cls-18', function () {
    if ($(this).hasClass('free')) {
      if (ReservedSeats < PersonCounter) {
        $(this).addClass('reserved')
        $(this).removeClass('free')
        EventChannel.send({ msg_type: 'reserved', data: $(this).closest('g').prop('id') })
        ReservedSeats++
        UpdateSeating()
      }
    } else if ($(this).hasClass('reserved')) {
      $(this).removeClass('reserved')
      $(this).addClass('free')
      EventChannel.send({ msg_type: 'free', data: $(this).closest('g').prop('id') })

      ReservedSeats--
      UpdateSeating()
    }
  })
}
)
